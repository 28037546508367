import { LoadingSpinner } from 'client/components/common/LoadingSpinner';
import { Pagination } from 'client/components/Gallery/Pagination';
import { getShowSpinnerSelector } from 'client/store/ux';
import { useSelector } from 'react-redux';
import {
    booleanRenderPropertySelector, getMetadata, getTLPL0Url, getPageTitle,
} from 'client/store/config/reducer';
import { getMarket, getTenant } from 'client/store/config';
import { RenderProperty } from 'shared/renderProperties';
import { ImageLoadedInstrumenter } from 'client/components/common/ImageLoadedInstrumenter';
import { Filters } from 'client/components/Gallery/Filters';
import { CareCallout } from 'client/components/Gallery/Footer/CareCallout';
import { Faq } from 'client/components/Gallery/Footer/Faq';
import { ProductOptionsDescriptions } from 'client/components/Gallery/Footer/ProductOptionsDescriptions';
import { QuickLinks } from 'client/components/Gallery/Footer/QuickLinks';
import { TLPL0Callout } from 'client/components/Gallery/Footer/TLPL0Callout';
import { ReviewsCallout } from 'client/components/Gallery/Footer/ReviewsCallout';
import { PreviewArea } from 'client/components/Gallery/PreviewArea';
import { ProductStructuredData } from 'client/components/Gallery/ProductStructuredData';
import { SecondaryContent } from 'client/components/Gallery/SecondaryContent';
import {
    SwanProvider,
    SWAN_BASE_URL_MAP,
    ScreenClassProvider,
    SiteMain,
    MainContentStart,
    BoundedContent,
    TextInput,
    useBrowserClasses,
    SwanSSRProvider,
    GridContainer,
    Row,
    Column,
} from '@vp/swan';
import { ErrorBoundary } from 'react-error-boundary';
import { getLastContentRequestUrl } from 'client/store/debug/reducer';
import { GalleryErrorPage } from '~/client/pages/error/GalleryErrorPage';
import { useInitPricingContext } from '~/client/hooks/useInitPricingContext';
import {
    Suspense, lazy, useEffect, useState,
} from 'react';
import { instrumenter } from '~/client/utils/instrumentation';
import { TRACKING_BEHAVIOR, useExperimentation } from 'client/hooks/useExperimentation';
import { Header } from 'client/components/Gallery/Header';
import { HeroHeader } from 'client/components/Gallery/Header/HeroHeader';
import { Subheader } from 'client/components/Gallery/Subheader';
import { LOGO_PREVIEW_VARIATIONS } from '~/experiments/PhotoPreviewBusiness/constants';
import { REVISED_DESIGN_FIRST_FLOW_VARIATIONS } from '~/experiments/RevisedDesignFirstFlow/constants';
import { containsColorFilter } from '~/experiments/ColorPalette/utils';
import { COLOR_PALETTE_VARIATIONS } from '~/experiments/ColorPalette/constants';
import { ASP_GLOBAL_RANKING_VARIATIONS } from '~/experiments/ASPSearch/ASPGlobalRankingExperiment';
import {
    refinementIdsByFilterSelector,
} from 'client/store/refinement/selectors';
import {
    PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS,
    PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS,
} from '~/experiments/tilePersonalization/constants';
import { currentPageSelector } from '~/client/store/paging';
import { useTranslations } from '~/client/hooks/useTranslations';
import { getPageTitleWithPagePostfix } from '~/client/utils/pageTitle';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { PersonalizationSidebar } from '~/client/components/Gallery/Filters/PersonalizationFilter/PersonalizationSidebar';
import { DESIGN_CONSOLIDATION_VARIATIONS } from '~/experiments/DesignConsolidation/constants';
import { MERCH_MODULES_VARIATIONS } from '~/experiments/MerchModules/constants';
import { MANUAL_VARIETY_BC_VARIATIONS } from '~/experiments/ManualVarietyBC/constants';
import { TEMPLATE_NEW_TILE_VARIATIONS } from '~/experiments/TemplateNewTile/constants';
import { FAVORITES_PAGE_VARIATIONS } from '~/experiments/MyFavoritesPage/constants';
import { useEventListener } from '~/client/hooks/useEventListener';
import { getLogger } from 'client/utils/gallery/logger';
import { PersonalizationWrapper } from './Filters/PersonalizationFilter/PersonalizationWrapper';

const Personalization = lazy(() => import('client/components/Gallery/Header/Personalization'));

export const Index = (): JSX.Element => {
    const localize = useTranslations();
    const { showSpinner } = useSelector(getShowSpinnerSelector);
    const galleryMetadata = useSelector(getMetadata);
    const lastContentRequestUrl = useSelector(getLastContentRequestUrl);
    const market = useSelector(getMarket);
    const tenant = useSelector(getTenant);
    const tlpL0Url = useSelector(getTLPL0Url);
    const pageTitle = useSelector(getPageTitle);
    const page = useSelector(currentPageSelector);

    // Render props
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const showProductInfo = booleanRenderProperty(RenderProperty.ShowProductInfo);
    const showQuicklinks = booleanRenderProperty(RenderProperty.ShowQuicklinks);
    const showTLPL0Url = booleanRenderProperty(RenderProperty.ShowTLPL0Quicklink);

    const shouldShowTlp0Callout = !showQuicklinks && showTLPL0Url && tlpL0Url;
    const refinements = useSelector(refinementIdsByFilterSelector);

    useBrowserClasses(true);
    useInitPricingContext(market, tenant);
    const isExperimentActive = useExperimentation();

    const [shouldRenderPersonalizationSidebar, setShouldRenderPersonalizationSidebar] = useState(false);
    const isLogoPreviewEnabled = isExperimentActive(LOGO_PREVIEW_VARIATIONS.Enabled);
    const isPersonalizationSidebarEnabled = isExperimentActive(
        PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || isExperimentActive(
        PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Enabled,
        TRACKING_BEHAVIOR.Suppress,
    ) || booleanRenderProperty(RenderProperty.ShowPersonalizationUI);
    const shouldRenderPersonalization = !isSmallScreen() && !isPersonalizationSidebarEnabled && (isLogoPreviewEnabled
        || booleanRenderProperty(RenderProperty.ShowPhotoPersonalization));

    useEffect(() => {
        setShouldRenderPersonalizationSidebar(!isSmallScreen() && isPersonalizationSidebarEnabled);
    }, [isPersonalizationSidebarEnabled]);

    if (!isPersonalizationSidebarEnabled && !isSmallScreen()) {
        isExperimentActive(PERSONALIZATION_UX_HOLIDAY_EXPANSION_VARIATIONS.Control);
        isExperimentActive(PERSONALIZATION_UX_SIGNAGE_STICKER_EXPANSION_VARIATIONS.Control);
    }

    useEffect(() => {
        // Fire experiment impression event for server side experiments
        isExperimentActive(LOGO_PREVIEW_VARIATIONS.Control);
        isExperimentActive(LOGO_PREVIEW_VARIATIONS.Enabled);
        instrumenter.recordAppHydratedEvent();
        isExperimentActive(REVISED_DESIGN_FIRST_FLOW_VARIATIONS.Control);
        isExperimentActive(REVISED_DESIGN_FIRST_FLOW_VARIATIONS.Enabled);
        isExperimentActive(DESIGN_CONSOLIDATION_VARIATIONS.SemiEnabled, TRACKING_BEHAVIOR.Force);
        isExperimentActive(DESIGN_CONSOLIDATION_VARIATIONS.Enabled, TRACKING_BEHAVIOR.Force);
        isExperimentActive(DESIGN_CONSOLIDATION_VARIATIONS.Control, TRACKING_BEHAVIOR.Force);
        isExperimentActive(ASP_GLOBAL_RANKING_VARIATIONS.GlobalRankingControl);
        isExperimentActive(ASP_GLOBAL_RANKING_VARIATIONS.GlobalRankingEnabled);
        isExperimentActive(MERCH_MODULES_VARIATIONS.Control);
        isExperimentActive(MERCH_MODULES_VARIATIONS.EnabledRow2);
        isExperimentActive(MERCH_MODULES_VARIATIONS.EnabledRow6);
        isExperimentActive(MERCH_MODULES_VARIATIONS.EnabledRow12);
        isExperimentActive(MERCH_MODULES_VARIATIONS.EnabledRow18);
        isExperimentActive(MANUAL_VARIETY_BC_VARIATIONS.Control);
        isExperimentActive(MANUAL_VARIETY_BC_VARIATIONS.Enabled);
        isExperimentActive(TEMPLATE_NEW_TILE_VARIATIONS.Control);
        isExperimentActive(TEMPLATE_NEW_TILE_VARIATIONS.Enabled);
        isExperimentActive(FAVORITES_PAGE_VARIATIONS.Control);
        isExperimentActive(FAVORITES_PAGE_VARIATIONS.Enabled);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // Color filter might exist on initial request, so we can't handle Experiment Viewed event just when clicking filter
    useEffect(() => {
        if (containsColorFilter(refinements.attributes)) {
            isExperimentActive(COLOR_PALETTE_VARIATIONS.Enabled);
            isExperimentActive(COLOR_PALETTE_VARIATIONS.Control);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refinements.attributes]);

    useEffect(() => {
        document.title = getPageTitleWithPagePostfix(pageTitle, page, localize('PageTitle'));
    }, [localize, page, pageTitle]);

    useEventListener('unhandledrejection', (event) => {
        const { reason } = event as unknown as PromiseRejectionEvent;

        getLogger().warning(
            `Unhandled Promise Rejection \nMessage: ${reason?.message} \nStack: ${reason?.stack}`,
            new Error(reason),
            {
                message: reason?.message,
                stack: reason?.stack,
            },
        );
    });

    return (
        <SwanProvider swanBaseUrl={SWAN_BASE_URL_MAP.relative}>
            <SwanSSRProvider>
                <ScreenClassProvider>
                    <SiteMain>
                        <MainContentStart />
                        <ErrorBoundary FallbackComponent={GalleryErrorPage}>
                            <HeroHeader />
                            <BoundedContent className="app">
                                <ImageLoadedInstrumenter />
                                <GridContainer>
                                    <Row>
                                        <Column span={12}>
                                            <Header />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column
                                            className="left-nav-wrapper"
                                            {...(isPersonalizationSidebarEnabled ? { p: 0 } : {})}
                                            span={2}
                                            spanLg={3}
                                            spanMd={3}
                                            spanSm={4}
                                            spanXl={2}
                                            spanXs={null}
                                        >
                                            <nav className="left-nav">
                                                <Suspense fallback={null}>
                                                    {shouldRenderPersonalization && <Personalization />}
                                                </Suspense>
                                                {shouldRenderPersonalizationSidebar && (
                                                <PersonalizationWrapper>
                                                    <PersonalizationSidebar />
                                                </PersonalizationWrapper>
                                                )}
                                                {!shouldRenderPersonalizationSidebar && <Filters />}
                                            </nav>
                                        </Column>
                                        <Column
                                            span={10}
                                            spanLg={9}
                                            spanMd={9}
                                            spanSm={8}
                                            spanXl={10}
                                            spanXs={12}
                                        >
                                            <Subheader />
                                            <PreviewArea />
                                            <Pagination />
                                            {(!!galleryMetadata && showProductInfo) && <SecondaryContent />}
                                            <section className="footer">
                                                <Faq />
                                                <ReviewsCallout />
                                            </section>
                                            <nav className="external-links">
                                                <ProductOptionsDescriptions />
                                                <QuickLinks />
                                                {shouldShowTlp0Callout && <TLPL0Callout />}
                                            </nav>
                                            <section className="care-callout-wrapper">
                                                <CareCallout />
                                            </section>
                                        </Column>
                                    </Row>
                                </GridContainer>
                                {showSpinner && (<LoadingSpinner />)}
                                <ProductStructuredData />
                            </BoundedContent>
                        </ErrorBoundary>
                    </SiteMain>
                    <TextInput id="_initialContentRequest" type="hidden" value={lastContentRequestUrl as string} />
                </ScreenClassProvider>
            </SwanSSRProvider>
        </SwanProvider>
    );
};
