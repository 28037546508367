import {
    Box,
    Button,
    Divider,
    FormField,
    FormInputGroup,
    Spinner,
    StandardForm,
    TextInput,
    TextInputFloatingLabel,
    TextInputWithButtonInset,
    TextInputWithFloatingLabel,
} from '@vp/swan';
import { batch, useDispatch, useSelector } from 'react-redux';
import {
    useState, useEffect,
    useCallback,
    useContext,
} from 'react';
import { useAuth } from '~/client/hooks/useAuth';
import { trackExperimentEngagement } from '~/shared/ab-testing';
import { getRawExperiments } from '~/client/store/experimentation';
import { useAnalytics } from '~/client/hooks/gallery/useAnalytics';
import { ANALYTICS_EVENT_ACTIONS } from '~/shared/constants';
import { isSmallScreen } from '~/client/utils/deviceDetection';
import { booleanRenderPropertySelector, getGalleryIdSelector } from '~/client/store/config';
import { useBrandContext } from '@vp/react-brand';
import { RenderProperty } from 'shared/renderProperties';
import { useTranslations } from '~/client/hooks/useTranslations';
import { scrollToElement } from 'client/utils/scrollToElement';
import { galleryHeaderId } from 'client/components/Gallery/Header/constants';
import {
    customImageDataUpdate, logoAppliedUpdate, personalizedTextUpdate,
} from 'client/store/personalization/actions';
import { designPersonalizationContextSelector } from 'client/store/personalization/selectors';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { selectedPhotosState } from 'client/atoms/selectedPhotosAtom';
import { contentUpdate } from 'client/store/content';
import {
    PHOTO_UPLOAD_STATES,
} from 'client/constants';
import { mobilePersonalizationOpenState } from '~/client/atoms/mobilePersonalizationOpenAtom';
import { photoUploadState } from '~/client/atoms/photoUploadStateAtom';
import { PersonalizationReactContext } from '~/client/contexts/PersonalizationReactContext';
import { splitFullName } from './splitFullName';
import { dpcToBrandContent, dpcToTextFields } from './dpcConverters';
import { PURPOSE_NAMES, TEXT_INPUT_DEBOUNCE_TIME_MS } from './constants';
import { TemplateField } from './purposeHelpers';
import { PersonalizationImageModal } from './PersonalizationImageModal';
import { usePurposeNames } from '../../Header/Personalization/hooks/usePurposeNames';
import { useRestoreDpc } from '../../Header/Personalization/hooks/useRestoreDpc';

export const PersonalizationFilter = (): JSX.Element => {
    const setPersonalizationModalOpen = useSetRecoilState(mobilePersonalizationOpenState);
    const {
        experimentName,
        experimentVariation,
        textfieldValues,
        setTextfieldValues,
        isSmallScreenPersonalizationEnabled,
        mobileTextfieldValues,
        setMobileTextfieldValues,
    } = useContext(PersonalizationReactContext);
    const [textDebounceFns, setTextDebounceFns] = useState<Record<string, NodeJS.Timeout>>({});
    const dispatch = useDispatch();
    const uploadState = useRecoilValue(photoUploadState);
    const [textUpdateLoading, setTextUpdateLoading] = useState<Record<string, boolean>>({});
    const auth = useAuth();
    const rawExperiments = useSelector(getRawExperiments);
    const analytics = useAnalytics();
    const brandcontext = useBrandContext();
    const [isLoadingBrand, setIsLoadingBrand] = useState(false);
    const [brandId, setBrandId] = useState<string | undefined>();
    const { createBrand, updateBrand } = brandcontext.api;
    const localize = useTranslations();
    const booleanRenderProperty = useSelector(booleanRenderPropertySelector);
    const shouldStoreToBrand = booleanRenderProperty(RenderProperty.PersonalizationStoreToBrand);
    const purposeNames = usePurposeNames();
    const selectedPhotos = useRecoilValue(selectedPhotosState);
    // Used for persisting dpc data
    const galleryId = useSelector(getGalleryIdSelector);

    const dpc = useSelector(designPersonalizationContextSelector);
    const personalizedText = dpcToTextFields(purposeNames, dpc);

    useRestoreDpc();

    const createOrUpdateBrand = useCallback(async (): Promise<void> => {
        const brandContent = dpcToBrandContent(dpc);

        // Don't do anything if there is no content
        if (Object.keys(brandContent).length === 0) {
            return;
        }

        // Only create a brand if there isn't an existing one
        if (!brandId && brandcontext.brands.length === 0) {
            try {
                setIsLoadingBrand(true);
                const newBrand = await createBrand(brandContent);

                setBrandId(newBrand.brandId);
            } finally {
                setIsLoadingBrand(false);
            }
        } else if (brandId) {
            try {
                setIsLoadingBrand(true);
                // patchBrand will update only data specified in request
                // updateBrand will replace all data. TODO: Switch to patchBrand when it works
                updateBrand({
                    brandId,
                    isActive: true,
                    isFactual: true,
                    ...brandContent,
                });
            } finally {
                setIsLoadingBrand(false);
            }
        }
        // Including the brand functions here causes infinite network calls
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId, dpc, brandcontext.isInitialized]);

    useEffect(() => {
        if (!shouldStoreToBrand || isLoadingBrand || !brandcontext.isInitialized) {
            return;
        }

        createOrUpdateBrand();
    }, [brandId, brandcontext.isInitialized, createOrUpdateBrand, isLoadingBrand, shouldStoreToBrand]);

    const updatePersonalizedText = useCallback((newTextInput: string, purposeName: PURPOSE_NAMES) => {
        if (newTextInput !== '') {
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                dispatch(personalizedTextUpdate({
                    ...personalizedText,
                    [purposeName]: newTextInput,
                    ...splitFullName(newTextInput),
                }, galleryId));
            } else {
                dispatch(personalizedTextUpdate({ ...personalizedText, [purposeName]: newTextInput }, galleryId));
            }
        } else {
            // Reset field (and derived fields) back to template placeholder
            // eslint-disable-next-line no-lonely-if
            if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                const {
                    fullname: _, firstname: _1, lastname: _2, ...newPersonalizedText
                } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            } else {
                const { [purposeName]: _, ...newPersonalizedText } = personalizedText;

                dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
            }
        }

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Text Personalized',
            experimentName,
            `Text Personalized: ${purposeName}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Personalized',
            eventDetail: `Text Personalized: ${purposeName}`,
            ...analytics.getPageProperties(),

        });
    }, [analytics, dispatch, experimentName, experimentVariation, galleryId, personalizedText, rawExperiments]);

    const handleTextChange = (templateField: TemplateField, event: React.ChangeEvent<HTMLInputElement>): void => {
        const { purposeName } = templateField;
        const newText = event.target.value;

        if (isSmallScreen()) {
            setMobileTextfieldValues?.({ ...mobileTextfieldValues, [purposeName]: newText });
            return;
        }

        setTextfieldValues?.({ ...textfieldValues, [purposeName]: newText });

        const debounceFn = textDebounceFns[purposeName];

        if (debounceFn) {
            clearTimeout(debounceFn);
            setTextUpdateLoading((prevState) => ({
                ...prevState,
                [purposeName]: false,
            }));
        }

        setTextDebounceFns({
            ...textDebounceFns,
            [purposeName]: setTimeout(() => {
                updatePersonalizedText(newText, purposeName);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }, TEXT_INPUT_DEBOUNCE_TIME_MS),
        });
        setTextUpdateLoading((prevState) => ({
            ...prevState,
            [purposeName]: true,
        }));
    };

    const handleEnterPress = (purposeName: PURPOSE_NAMES, event: React.KeyboardEvent<HTMLInputElement>): void => {
        if (event.key === 'Enter' && textfieldValues[purposeName] !== personalizedText[purposeName]) {
            trackExperimentEngagement(
                experimentVariation,
                rawExperiments,
                analytics,
                ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                'Text Personalized - Enter Key Submit',
                experimentName,
                `Text Personalized: ${purposeName}`,
            );
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Text Personalized - Enter Key Submit',
                eventDetail: `Text Personalized: ${purposeName}`,
                ...analytics.getPageProperties(),

            });

            if (textDebounceFns[purposeName]) {
                clearTimeout(textDebounceFns[purposeName]);
                setTextUpdateLoading((prevState) => ({
                    ...prevState,
                    [purposeName]: false,
                }));
            }

            updatePersonalizedText(textfieldValues[purposeName], purposeName);
        }
    };

    const trackTextFocus = (purposeName: string): void => {
        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Text Field Selected',
            experimentName,
            `Text Field Selected: ${purposeName}`,
        );
        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Text Field Selected',
            eventDetail: `Text Field Selected: ${purposeName}`,
            ...analytics.getPageProperties(),
        });
    };

    const handleMobileSubmitPersonalization = (): void => {
        // updatePersonalizedText() doesn't work for bulk text updates, only single field updates
        const newPersonalizedText: Record<string, string> = {};

        if (mobileTextfieldValues) {
            Object.keys(mobileTextfieldValues).forEach((purposeName) => {
                const textfieldValue = mobileTextfieldValues[purposeName];

                if (textfieldValue !== '') {
                    if (purposeName === PURPOSE_NAMES.FULL_NAME) {
                        const splitName = splitFullName(textfieldValue);

                        newPersonalizedText.firstname = splitName.firstname;
                        newPersonalizedText.lastname = splitName.lastname;
                    }
                    newPersonalizedText[purposeName] = textfieldValue;
                }

                trackExperimentEngagement(
                    experimentVariation,
                    rawExperiments,
                    analytics,
                    ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    'Text Personalized - Mobile Submit',
                    experimentName,
                    `Text Personalized: ${purposeName}`,
                );

                analytics.trackEvent({
                    action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                    eventLabel: 'Text Personalized - Mobile Submit Button',
                    eventDetail: `Text Personalized: ${purposeName}`,
                    ...analytics.getPageProperties(),
                });
            });
        }

        dispatch(personalizedTextUpdate(newPersonalizedText, galleryId));
        batch(() => {
            analytics.trackEvent({
                action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
                eventLabel: 'Personalization modal images applied',
                eventDetail: `${selectedPhotos.length} Personalization modal images applied`,
                ...analytics.getPageProperties(),
            });
            dispatch(customImageDataUpdate(selectedPhotos, galleryId));
            dispatch(logoAppliedUpdate(!!selectedPhotos.length));
            dispatch(contentUpdate());
        });

        trackExperimentEngagement(
            experimentVariation,
            rawExperiments,
            analytics,
            ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            'Submit Personalization Clicked',
            experimentName,
            `Submit Personalization Clicked`,
        );

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.BUTTON_CLICKED,
            eventLabel: 'Personalization submit button clicked',
            eventDetail: `Personalization submit button clicked`,
            ...analytics.getPageProperties(),
        });

        setPersonalizationModalOpen(false);
        setTimeout(() => {
            scrollToElement(galleryHeaderId, { behavior: 'auto', block: 'start', inline: 'nearest' });
        }, 100);
    };

    return auth ? (
        <section aria-label="personalize" className="personalization-container">
            <div />
            <Box
                className="personalization-upload-button-container"
                mb={isSmallScreenPersonalizationEnabled ? 'to-actions' : 5}
            >
                <PersonalizationImageModal />
            </Box>
            <StandardForm autoComplete="off">
                {purposeNames.map((p) => (
                    <FormField className="personalization-form-field" key={p.purposeName}>
                        <FormInputGroup mb={5}>
                            {isSmallScreenPersonalizationEnabled && (
                            <TextInputWithFloatingLabel>
                                <TextInput
                                    className="personalization-text-input"
                                    placeholder={p.placeholder}
                                    value={mobileTextfieldValues[p.purposeName] || ''}
                                    onChange={(e): void => handleTextChange(p, e)}
                                    onFocus={(): void => trackTextFocus(p.purposeName)}
                                />
                                <TextInputFloatingLabel>
                                    {p.placeholder}
                                </TextInputFloatingLabel>
                            </TextInputWithFloatingLabel>
                            )}
                            {!isSmallScreenPersonalizationEnabled && (
                            <TextInputWithFloatingLabel>
                                <TextInputWithButtonInset>
                                    <TextInput
                                        className="personalization-text-input"
                                        placeholder={p.placeholder}
                                        value={textfieldValues[p.purposeName] || ''}
                                        onChange={(e): void => handleTextChange(p, e)}
                                        onFocus={(): void => trackTextFocus(p.purposeName)}
                                        onKeyDown={(e): void => handleEnterPress(p.purposeName, e)}
                                    />
                                    {textUpdateLoading[p.purposeName] && <Spinner accessibleText={localize('Loading')} className="personalization-text-input-spinner" size="tiny" />}
                                    <TextInputFloatingLabel>
                                        {p.placeholder}
                                    </TextInputFloatingLabel>
                                </TextInputWithButtonInset>
                            </TextInputWithFloatingLabel>
                            )}
                        </FormInputGroup>
                    </FormField>
                ))}
            </StandardForm>
            {isSmallScreenPersonalizationEnabled
                && (
                    <>
                        <Divider mb={6} mt={6} />
                        <Button
                            disabled={uploadState.status === PHOTO_UPLOAD_STATES.LOADING}
                            skin="primary"
                            width="full-width"
                            onClick={handleMobileSubmitPersonalization}
                        >
                            {localize('PersonalizationSubmitButtonText')}
                        </Button>
                    </>
                )}
        </section>
    ) : (<Box loadingShimmer className="personalization-container-skeleton" />);
};
