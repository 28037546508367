import config from 'config';
import { Tile } from 'client/components/common/Tile';
import { Typography, Icon, Link } from '@vp/swan';
import { getLocaleSelector, getMpvid } from 'client/store/config';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslations } from 'client/hooks/useTranslations';
import { buildFullyQualifiedVistaprintUrl } from 'client/utils/vistaprintUrlBuilder';
import { useAnalytics } from 'client/hooks/gallery/useAnalytics';
import {
    ANALYTICS_PAGE_ZONE,
    ANALYTICS_CTA_VALUE,
    URL_TARGET,
} from 'client/components/Gallery/PreviewArea/CareTile/constants';
import { ANALYTICS_EVENT_ACTIONS } from 'shared/constants';

type PropTypes = {
    previewHeight: number;
};

export const CareTile = ({ previewHeight }: PropTypes): JSX.Element => {
    const localize = useTranslations();
    const locale = useSelector(getLocaleSelector);
    const mpvId = useSelector(getMpvid);

    const url = buildFullyQualifiedVistaprintUrl({
        path: URL_TARGET,
        locale,
        mpvId,
    });
    const analytics = useAnalytics();
    const { pathname } = useLocation();

    const onClick = (): void => {
        const eventDetailList = [
            pathname,
            URL_TARGET,
            ANALYTICS_PAGE_ZONE.CARE_TILE,
            ANALYTICS_CTA_VALUE.EXPERT_SERVICES_DESIGN_SERVICES_BRIEF,
        ];

        analytics.trackEvent({
            action: ANALYTICS_EVENT_ACTIONS.NAVIGATION_CLICKED,
            eventLabel: `Content:${ANALYTICS_PAGE_ZONE.CARE_TILE}`,
            eventDetail: eventDetailList.join(';'),
            navigationDetail: ANALYTICS_CTA_VALUE.EXPERT_SERVICES_DESIGN_SERVICES_BRIEF,
            route: config.client.segmentRoute,
        });
    };

    return (
        <Tile
            style={{
                minHeight: previewHeight,
            }}
            type="care-tile"
        >
            <Link aria-label={localize('CareTileHeading')} className="care-tile-action-area" href={url} skin="unstyled" onClick={onClick}>
                <div className="care-tile-content">
                    <div className="care-tile-icon-circle">
                        <Icon
                            className="care-tile-icon"
                            iconType="workWithAPro"
                        />
                    </div>
                    <Typography className="care-tile-header-text" fontWeight="bold">
                        {localize('CareTileHeading')}
                    </Typography>
                    <Typography fontSize="small">{localize('CareTileCaptionText')}</Typography>
                </div>
            </Link>
        </Tile>
    );
};

CareTile.displayName = 'CareTile';
