import { ASPExperiment } from './ASPExperiment';

export enum ASP_GLOBAL_RANKING_VARIATIONS {
    GlobalRankingEnabled = 'ASPGlobalRankingEnabled',
    GlobalRankingControl = 'ASPGlobalRankingControl',
}

export const ASPGlobalRankingExperiment = {
    experimentKey: 'global_ranking_p2',
    variationsValueMap: {
        [ASP_GLOBAL_RANKING_VARIATIONS.GlobalRankingEnabled]: '2',
        [ASP_GLOBAL_RANKING_VARIATIONS.GlobalRankingControl]: '1',
    },
} as ASPExperiment;
