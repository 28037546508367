export enum DESIGN_CONSOLIDATION_VARIATIONS {
    SemiEnabled = 'DesignConsolidationEnabledNoMessaging',
    Enabled = 'DesignConsolidationEnabled',
    Control = 'DesignConsolidationControl',
}

export const DESIGN_CONSOLIDATION_EXPERIMENT_NAME = 'gallery_design_consolidation_hc';
export const GREETING_CATEGORY_ID = '1243';
export const GREETING_TUC_ID = '888dec3d-9a34-4cdf-8c82-c5e232854367';
export const CONSOLIDATION_FORCED_RANKING_STRATEGY_NAME = 'Holiday Cards NA FY25 Consolidated';
