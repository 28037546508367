import config from 'config';
import { formatString } from 'shared/localization';

const formatPageTitle = (message: string, name: string): string => formatString(message, { name });

export const getPageTitle = (configState: Gallery.ConfigApi.Config, titleTemplate: string): string => {
    if (configState.metadata.seoMetadata?.title) {
        return configState.metadata.seoMetadata.title.trim();
    }

    return configState.h1Title
        ? formatPageTitle(titleTemplate, configState.h1Title)
        : config.client.defaultPageTitle.trim();
};

export const getPageTitleWithPagePostfix = (title: string, page: number, pageTranslation: string): string => {
    const postfix = page > 1 ? ` - ${pageTranslation} ${page}` : '';

    return `${title}${postfix}`;
};
